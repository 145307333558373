import { Drawer, Grid, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import GROMO_LOGO from "../public/assets/images/home/GromoLogo.svg";
import PlayStoreIconOnly from "../public/assets/images/home/PlayStoreIconOnly.svg";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import Utils from "../utils/Utils";
import { GROMOLOGO_WHITE, PLAYSTORE_BLACK } from "../utils/ImageExport";
import { appLink } from "../utils/Constants";
import Link from "next/link";
import Image, { StaticImageData } from "next/image";
import { useRouter } from "next/router";

export default function AppNavBar({ navBgColor }: { navBgColor?: string }) {
  const router = useRouter();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [imgSrc, setImgSrc] = useState<StaticImageData>();
  const [downloadBtn, setDownloadBtn] = useState(false);
  const [showIcon, setShowIcon] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");

  useEffect(() => {
    const handleResize = () => {
      if (globalThis.window?.location.pathname !== "/") {
        setShowIcon(true);
        setDownloadBtn(true);
        setImgSrc(
          Utils.getOS()?.toLocaleLowerCase() === "android"
            ? PLAYSTORE_BLACK
            : undefined
        );
      } else {
        setDownloadBtn(true);
      }
    };

    const handleScroll = () => {
      if (globalThis.window?.location.pathname === "/") {
        setShowIcon(globalThis.window.pageYOffset > 210);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const RightDrawer = () => (
    <Drawer
      anchor="right"
      open={drawerOpen}
      onClose={() => setDrawerOpen(false)}
      sx={{ minWidth: "230px !important" }}
    >
      <Grid
        sx={{
          width: "100%",
          padding: "15px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderBottom: "1px solid #0000001a",
          height: "56px",
        }}
      >
        <Box sx={{ width: "100px", height: "35px" }}>
          <Link href="/">
            <Image
              src={GROMO_LOGO}
              alt="gromoLogo"
              width={100}
              height={100}
              style={{ objectFit: "contain" }}
              loading="lazy"
            />
          </Link>
        </Box>
        <Box onClick={() => setDrawerOpen(false)} paddingRight="18px">
          <CloseIcon sx={{ color: "#212736" }} />
        </Box>
      </Grid>
      <Grid
        width="100%"
        sx={{
          width: "100%",
          color: "#0A0C0F",
        }}
      >
        <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
          {[
            { name: "Insurance-POS", link: "https://pos.gromoinsure.in/" },
            { name: "Apply for Products", link: "/customer" },
            { name: "Careers", link: "/careers" },
            { name: "About us", link: "/about-us" },
            { name: "Blogs", link: "https://gromo.in/blog/" },
          ].map((item) => (
            <li key={item.name} style={{ margin: "8px 0" }}>
              <Link href={item.link}>
                <Typography
                  variant="h6"
                  component="div"
                  fontSize="16px"
                  padding="0 16px"
                  sx={{
                    background:
                      selectedItem === item.name ? "lightgrey" : "white",
                    display: "block",
                    textDecoration: "none",
                    color: "inherit",
                  }}
                  onClick={() => setSelectedItem(item.name)}
                >
                  {item.name}
                </Typography>
              </Link>
            </li>
          ))}
          <li style={{ margin: "8px 0" }}>
            <Typography
              variant="h6"
              component="div"
              fontSize="16px"
              padding="0 16px"
              onClick={() => {
                setDrawerOpen(false);
                window.scroll(0, document?.body?.scrollHeight);
              }}
              sx={{
                display: "block",
                textDecoration: "none",
                color: "inherit",
              }}
            >
              Contact us
            </Typography>
          </li>
        </ul>
      </Grid>
    </Drawer>
  );

  const pushToPage = (name: string, redirectionLink: string) => {
    if (name === "Blogs") {
      window.open("https://gromo.in/blog/", "_blank");
    } else {
      router.push(redirectionLink);
    }
  };

  return (
    <Grid
      sx={{
        height: { xs: "56px", md: "73px" },
        width: "100%",
        position: "fixed",
        zIndex: 20,
        top: 0,
        borderBottom: "1px solid #EAF0FF24",
        boxShadow:
          navBgColor === "#002561" ? "0px 0px 4px rgba(0, 0, 0, 0.15)" : "",
      }}
    >
      <Grid
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          height: "100%",
          padding: "0px 15px",
          background: navBgColor || "#002561",
          color: navBgColor === "#002561" ? "black" : "#002561",
          transition: "background 1s ease",
        }}
      >
        <Grid
          sx={{
            display: {
              xs: "block",
              md: "none",
              display: "flex",
              alignItems: "center",
            },
            width: "100px",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Link href="/">
            <Image
              width={100}
              height={100}
              src={navBgColor === "white" ? GROMOLOGO_WHITE : GROMOLOGO_WHITE}
              alt="GromoLogo"
              loading="lazy"
              style={{ objectFit: "contain", marginTop: "14px" }}
            />
          </Link>
        </Grid>
        <Grid
          sx={{ display: { xs: "none", md: "flex" }, alignItems: "center" }}
        >
          <Grid sx={{ paddingLeft: "3%", width: "135px" }}>
            <Link href="/">
              <Image
                width={100}
                height={100}
                src={navBgColor === "white" ? GROMO_LOGO : GROMOLOGO_WHITE}
                alt="GromoLogo"
                style={{ objectFit: "contain" }}
                loading="lazy"
              />
            </Link>
          </Grid>
          <Grid
            sx={{
              display: { xs: "none", md: "flex" },
              justifyContent: "space-around",
              alignItems: "center",
              listStyle: "none",
              width: "fit-content",
              cursor: "pointer",
              fontSize: "16px",
              padding: navBgColor === "white" ? "0" : "10px",
            }}
          >
            <ul
              style={{
                listStyleType: "none",
                display: "flex",
                padding: 0,
                margin: 0,
              }}
            >
              {[
                { name: "Insurance-POS", link: "https://pos.gromoinsure.in/" },
                { name: "Apply for Products", link: "/customer" },
                { name: "Careers", link: "/careers" },
                { name: "About us", link: "/about-us" },
                { name: "Blogs", link: "https://gromo.in/blog/" },
              ].map((item) => (
                <li
                  key={item.name}
                  style={{
                    padding: "4px",
                    minWidth: "fit-content",
                    margin: "auto auto auto 32px",
                  }}
                >
                  <p
                    onClick={() => pushToPage(item.name, item.link)}
                    style={{
                      margin: "auto",
                      color: navBgColor === "white" ? "#393F50" : "white",
                    }}
                  >
                    {item.name}
                  </p>
                </li>
              ))}
              <li
                style={{
                  width: "105px",
                  height: "40px",
                  margin: "auto auto auto 32px",
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={() => {
                  setDrawerOpen(false);
                  window.scroll(0, document?.body?.scrollHeight);
                }}
              >
                <p
                  style={{
                    margin: "auto",
                    color: navBgColor === "white" ? "#393F50" : "white",
                  }}
                >
                  Contact us
                </p>
              </li>
            </ul>
          </Grid>
        </Grid>
        <Grid
          sx={{
            display: { xs: "none", md: "flex" },
            justifyContent: "right",
            alignItems: "center",
            listStyle: "none",
            width: "35%",
            padding: "10px",
          }}
        >
          <ul style ={{listStyleType:'none',display:'flex',alignItems:'center',padding:'5px',marginTop:'10px'}}>
            <li
              style={{
                padding: "5px",
                color: navBgColor === "white" ? "#393F50" : "white",
              }}
            >
              <Link href={appLink}>Download</Link>
            </li>
            <li
              style={{
                height: "40px",
                width: "40px",
                background: navBgColor === "#002561" ? navBgColor : "black",
                borderRadius: "8px",
                padding: "5px",
                cursor: "pointer",
                margin: "5px 16px",
                border: navBgColor === "#002561" ? "1px solid #D4DFFF" : "none",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Link href={appLink}>
                <Image
                  width={100}
                  height={100}
                  src={PlayStoreIconOnly}
                  alt="appStoreIcon"
                  style={{ objectFit: "contain" }}
                />
              </Link>
            </li>
          </ul>
        </Grid>
        <Grid sx={{ display: ["block", "block", "none"] }}>
          <Button
            href={appLink}
            sx={{
              color: "white",
              marginRight: "24px",
              background:
                globalThis.window?.screen.availWidth < 500
                  ? "#268BF0"
                  : "transparent",
              padding: downloadBtn ? "0 8px" : "0",
            }}
          >
            {downloadBtn ? (
              <Typography variant="h6" textTransform={"none"} fontSize={16}>
                Download
              </Typography>
            ) : showIcon ? (
              <Image
                src={imgSrc!}
                alt="download link"
                style={{ objectFit: "contain" }}
                width={100}
                height={40}
                loading="eager"
                priority
              />
            ) : null}
          </Button>
          <MenuIcon
            sx={{
              color: {
                xs: "white",
                md: navBgColor === "#002561" ? "black" : "white",
              },
              marginRight: "18px",
              fontSize: "24px",
            }}
            onClick={() => setDrawerOpen(true)}
          />
        </Grid>
        <RightDrawer />
      </Grid>
    </Grid>
  );
}
