import GromologoWhite from "../public/assets/images/home/GromologoWhite.svg";
import playStoreBlack from "../public/assets/images/home/playStoreBlack.svg";
import appStoreBlack from "../public/assets/images/home/appStoreBlack.svg";
import curveUp from "../public/assets/images/home/curveUp.webp";
import curveDown from "../public/assets/images/home/curveDown.webp";
import playStoreWhite from "../public/assets/images/home/playStoreWhite.svg";
import appStoreWhite from "../public/assets/images/home/appStoreWhite.svg";

export const GROMOLOGO_WHITE = GromologoWhite;
export const PLAYSTORE_BLACK = playStoreBlack;
export const APPSTORE_BLACK = appStoreBlack;
export const CURVEDOWN = curveDown;
export const CURVEUP = curveUp;
export const PLAYSTORE_WHITE = playStoreWhite;
export const APPSTORE_WHITE = appStoreWhite;
